import styled from 'styled-components'

export const Subtitle = styled.p`
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #7F7F7F;
  font-weight: 500;

  @media (min-width:768px) {
    margin-bottom: 35px;
  }
`

export const Title = styled.h1`
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;

  @media (min-width:768px) {
    font-size: 60px;
    margin-bottom: 35px;
  }

  @media (min-width: 1024px) {
    margin-top: 100px;
  }
`

export const Content = styled.div`

@media (min-width: 1024px) {
}

  > div p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 22px;
    padding: 0 20px;
    line-height: 1.5;

    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 30px;

    }

    @media (min-width:1024px) {
      padding: 0;
    }
  }



  a {
    font-weight: bold;
    color: #000000;
    text-decoration: none;
  }
`

export const Body = styled.div`

  p {
    font-weight: 400;
    text-align: center;

  
  }
`