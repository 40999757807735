import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from 'gatsby'
import { WorkGrid } from '$components'
import { Title, Content, Body} from '../components/styles'

const WorkPage = ({ data: { post, offer, image } }) => {


  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        keywords={[`gatsby`, `application`, `react`]}
      />

      <div style={{ maxWidth: '800px', margin: '0 auto' }}>
        <Content>
          <Title>{post.frontmatter.title}</Title>


          <Body>
            <MDXRenderer>{post.body}</MDXRenderer>
          </Body>
        </Content>
      </div>

      <WorkGrid />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
        subtitle
      }
    }
  }
`

export default WorkPage
